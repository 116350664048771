.modal {
  padding: 15px;
  ul{
    list-style: none;
  }
  &__btn{
    display: flex;
    justify-content: center;
    cursor: pointer;
    outline: none;

      padding: 14px 85px;
      background:  #0093FF;
      border-radius: 25px;
      border: none;
      text-align: center;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      cursor: pointer;
      color: #FFFFFF;
      &:focus{
        outline: none;
      }
  @media screen and (max-width: 768px) {
    padding: 14px 50px;
  }
      


  }

  .modalBackModal {
    display: 'flex';
    align-items: 'center'; 
    flex-direction: 'column';
    text-align: center;
  }

  @media screen and (max-width: 768px) {
    .modalBackModal > div {
      flex-direction: column;
    }

    .modalBackModal button {
      margin: 10px 0 !important;
    }
  }

}
.modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 20px;
}
.modal > .content {
  width: 100%;
  padding: 10px 5px;
}
.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}
.popup-content{
  @media (max-width: 768px){
    width: 80%;
  }
}
.hide{
  display: none;
  @media (max-width: 768px){
    display: block;
  }
}