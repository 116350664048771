.books {
    display: flex;
    position: relative;
    flex: 1;
    &__inner {
        display: flex;
        flex: 1;
        flex-direction: column; }
    &__suptitle {
        font-weight: 700;
        font-size: 28px;
        line-height: 33px;
        color: #39414C;
        margin-bottom: 40px; }
    &__sale {
        position: absolute;
        &_title {
            z-index: 48;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            transform: rotateZ(-45deg);
            font-size: 25px;
            padding: 8px;
            color: white;
            text-align: center; }
        img {
            width: 98px;
            height: 98px; } }
    &__items {
        display: flex;
        flex-wrap: wrap; }
    &__item {
        overflow: hidden;
        color: #39414C;
        margin-bottom: 24px;
        width: 33%;
        border-radius: 15px;
        padding-right: 24px; }
    &__img {
        img {
            width: 100%; } }
    &__wrapper {
        // display: flex
 }        // justify-content: space-between
    &__title {
        margin-top: 20px;
        font-weight: 600;
        font-size: 22px;
        line-height: 26px; }
    &__subtitle {
        margin-top: 15px;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        margin-bottom: 25px;
        span {
            text-decoration: line-through;
            margin-right: 10px; }
        img {
            margin-top: -2px;
            width: 13px;
            height: 13px; } }
    &__btn {
        border: none;
        background: #0093FF;
        border-radius: 25px;
        width: 100%;
        padding: 16px 0;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        color: #FFFFFF;
        cursor: pointer; } }

@media ( max-width: 767px ) {
    .books {
        &__inner {
            margin-top: 30px; }
        &__suptitle {
            margin-bottom: 30px; }
        &__items {
            justify-content: center; }
        &__item {
            margin-bottom: 30px;
            width: 100%; } } }
