.faq {
    display: flex;
    flex: 1;
    &__inner {
        flex: 1;
        display: flex;
        flex-direction: column; }
    &__title {
        font-weight: 700;
        font-size: 28px;
        line-height: 33px;
        color: #39414C; }
    &__wrapper {
        margin-top: 40px;
        display: flex; }
    &__contacts {
        width: 43%;
        margin-left: 24px; }
    &__contacts-inner {
        border: 1px solid #DADADA;
        box-sizing: border-box;
        border-radius: 15px;
        padding: 30px 25px; }
    &__contacts-question {
        font-size: 22px;
        line-height: 26px;
        color: #39414C;
        font-weight: 700;
        margin-bottom: 20px; }
    &__contacts-subtitle {
        font-weight: 500;
        font-size: 18px;
        line-height: 140%;
        color: #39414C;
        margin-bottom: 30px; }
    &__contacts-descr {
        font-weight: 700;
        font-size: 22px;
        line-height: 26px;
        color: #39414C;
        margin-bottom: 20px; }
    &__contacts-numbers {}
    &__contacts-number {
        font-weight: 500;
        font-size: 18px;
        line-height: 140%;
        cursor: pointer;
        color: #0093FF;
        & + .faq__contacts-number {
            margin-top: 10px; } }
    &__items {
        width: 53%; }

    &__item {
        background: #F6F6F6;
        border-radius: 15px;
        margin-bottom: 24px; }
    &__plus {
        position: absolute;
        right: 30px;
        top: 37%; }
    &__question {
        position: relative;
        padding: 30px;
        background: #0093FF;
        border-radius: 15px;
        font-weight: 600;
        font-size: 22px;
        line-height: 26px;
        color: #FFFFFF;
        padding-right: 50px;
        cursor: pointer; }
    &__answer {
        padding: 24px 30px;
        font-weight: 500;
        font-size: 18px;
        line-height: 140%;
        color: #39414C; } }

@media ( max-width: 767px ) {
    .faq {
        margin-bottom: 60px;
        &__inner {
            margin-top: 30px;
            width: 100%; }
        &__wrapper {
            flex-wrap: wrap; }
        &__items {
            width: 100%;
            order: 2; }
        &__contacts {
            margin-top: 40px;
            width: 100%;
            margin-left: 0;
            order: 1;
            margin-bottom: 20px; } } }

@media (min-width: 768px) and (max-width: 991px) {
    .faq {
        &__inner {
            width: 100%; } } }
