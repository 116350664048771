.tests {
    display: flex;
    flex: 1;
    &__inner {
        display: flex;
        flex: 1;
        flex-direction: column; }
    &__suptitle {
        font-weight: 700;
        font-size: 28px;
        line-height: 33px;
        color: #39414C;
        margin-bottom: 40px;
        display: flex;
        align-items: center;
        img {
            cursor: pointer;
            margin-right: 24px; } }
    &__description {
        font-size: 18px;
        line-height: 140%;
        margin-bottom: 40px; }
    &__items {
        display: flex;
        flex-wrap: wrap; }

    &__item {
        margin-bottom: 24px;
        cursor: pointer;
        padding-right: 25px;
        position: relative;
        width: 33%; }
    &__img {
        width: 100%; }

    &__wrapper {
        display: flex;
        justify-content: space-between;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 15px;
        padding: 20px; }
    &__descr {
        color: #ffffff; }
    &__title {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 10px; }
    &__subtitle {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px; }


    &__group {
        &__items {
            display: flex;
            flex-wrap: wrap; }
        &_item {
            width: 33%;
            margin: 10px 0;
            padding-right: 25px;
            &:hover {
                opacity: 0.8; }

            &__title {
                font-size: 20px;
                text-align: center;
                display: flex;
                justify-content: space-between;
                background-color: #CFD4DB;
                cursor: pointer;
                border-radius: 10px;
                padding: 20px 24px;
                font-weight: bold;
                color: #888D94;
                &-active {
                    background: linear-gradient(139.73deg, #0093FF 3.85%, #0058BA 99.1%);
                    color: white; } } } } }


@media ( max-width: 767px ) {
    .tests {
        &__inner {
            margin-top: 30px; }
        &__items {
            justify-content: center; }
        &__item {
            margin-left: 20px;
            margin-right: 20px;
            width: 100%; }
        &__group {
            &_item {
                width: 100%;
                padding: 0; } } } }
