.book-buy {
    display: flex;
    flex: 1;
    &__inner {
        display: flex;
        flex-direction: column;
        flex: 1; }
    &__title {
        font-size: 28px;
        font-weight: 700;
        line-height: 33px;
        color: #39414C;

        img {
            margin-right: 20px;
            cursor: pointer; } }
    &__name {
        font-weight: 600;
        font-size: 22px;
        line-height: 26px;
        padding-top: 30px;
        padding-bottom: 30px; }
    &__description {
        font-weight: 500;
        font-size: 18px;
        line-height: 140%;
        margin-bottom: 30px;
        margin-top: 10px; }
    &__label {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 5px;
        color: #888D94; }
    &__wrapper {
        margin-top: 40px;
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 30px; }
    &__img {
        width: 40%; }
    &__descr {
        padding-left: 24px;
        flex: 1; } }

.custom-slider {
    border-radius: 10px;
    overflow: hidden;
    background: transparent; }

@media ( max-width: 767px ) {
    .book-buy {
        &__img {
            width: 100%; } } }
