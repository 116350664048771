.error {
    color: red;
    margin-bottom: 20px; }
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0 /* <-- Apparently some margin are still there even though it's hidden */; }


.authorization {
    width: 100%;
    &__inner {
        display: flex; }
    &__formWrap {
        display: flex;
        align-items: center;
        margin: 0 auto; }
    &__wrapper {
        width: 50%;
        min-height: 100vh;
        background-image: url('./images/authorization-bg.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
    &__logo-mobile {
        display: none; }
    &__img {
        margin-top: 100px; }
    &__form {
        padding: 40px;
        border-radius: 15px;
        border: 1px solid #DADADA;
        width: 448px; }
    &__txt {
        display: flex;
        font-weight: 700;
        font-size: 28px;
        line-height: 33px;
        color: #888D94;
        margin-bottom: 40px; }
    &__login {
        margin-right: 30px;
        cursor: pointer; }
    &__registration {
        cursor: pointer; }
    &__active {
        color: #0093FF; }
    &__login, &__registration {
        transition: all 0.3s;
        &:hover {
            color: #0093FF; } }
    &__input {
        padding: 14px 20px;
        border: none;
        border-radius: 25px;
        background: #F9F9F9;
        margin-bottom: 24px;
        width: 368px;
        outline: none;
        &::placeholder {
            color: #39414C;
            font-weight: 500;
            font-size: 16px;
            line-height: 21px; } }
    &__select {
        overflow: hidden;
        appearance: none;
        background-image: url('./images/arrow.svg');
        background-repeat: no-repeat;
        background-position: right;
        background-position-x: 96%;
        color: #39414C;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        option {
            color: #39414C;
            font-weight: 500;
            font-size: 16px;
            line-height: 21px; } }
    &__checkbox {
        border-radius: 50px; }
    label {
        margin-left: 10px;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #39414C; }
    &__btn {
        background: #0093FF;
        border-radius: 25px;
        border: none;
        color: #ffffff;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        display: block;
        padding: 14px 0px;
        width: 368px;
        outline: none !important;
        &-disabled {
            background: rgba(77, 42, 239, 0.53); }
        &-log {
            margin-top: 40px; }
        &-register {
            margin-top: 0; } }
    &__forgot {
        display: flex;
        justify-content: center; }
    &__forgot-btn {
        outline: none !important;
        margin-top: 24px;
        border: none;
        background: none;
        color: #0093FF;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px; }
    &__restore-txt {
        color: #39414C; }
    &__restore-title {
        font-weight: bold;
        font-size: 28px;
        line-height: 33px;
        margin-bottom: 20px; }
    &__restore-subtitle {
        font-weight: 500;
        font-size: 18px;
        line-height: 140%; }
    &__restore-subtitles {
        margin-bottom: 40px; } }

@media ( max-width: 767px ) {
    .authorization {
        height: 100vh !important;
        position: relative;
        &__inner {
            display: flex;
            flex-direction: column; }
        &__wrapper {
            display: none !important; }
        &__formWrap {
            align-items: start !important; }
        &__logo-mobile {
            display: block !important;
            position: absolute;
            top: 10px;
            left: 20px;
            img {
                width: 122px !important; } }
        &__form {
            margin-top: 80px !important;
            width: 100% !important;
            border: none !important;
            padding: 20px !important; }
        &__input {
            width: 100% !important; }
        &__btn {
            width: 100% !important;
            text-align: center !important;
            padding: 14px 0 !important; } } }

// @media screen and ( max-width: 365px )
// @media (min-width: 768px) and (max-width: 991px)
@media (min-width: 768px) and (max-width: 1199px) {
    .authorization {
        height: 100vh !important;
        position: relative;
        &__wrapper {
            display: none !important; }
        &__formWrap {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%); }
        &__logo-mobile {
            display: block !important;
            position: absolute;
            left: 20px;
            top: 10px;
            img {
                width: 150%; } } } }


@media screen and ( min-width: 1920px ) {
    .authorization {
        &__img {
            margin-top: 106px !important; } } }

