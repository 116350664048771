@import "~hamburgers/_sass/hamburgers/hamburgers";

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after{
  height: 3px;
  width: 30px;
}
.hamburger.is-active{
  &:hover{
    opacity: 1;
  }
  .hamburger-inner{
    background-color: white;
  }
  .hamburger-inner::before, .hamburger-inner::after{
    background-color: white;
  }
}
button{
  &:focus{
    outline: none !important;
  }
}
.hamburger{
  padding-left: 0;
  padding-right: 0;
}
