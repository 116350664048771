/*  =================Общие стили================ */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: SF-Pro,serif; }
html {
    height: 100%; }
@font-face {
    font-family: SF-Pro;
    src: url('./fonts/SF-PRO.ttf'); }

*::before,*::after {
    box-sizing: inherit; }

a {
    text-decoration: none; }

h1,h2,h3,h4,h5,h6 {
    margin: 0; }

.App {
    display: flex; }
.custom-container {
    flex: 1;
    padding-left: 25px;
    padding-right: 25px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    background: #fafafa;
    padding-top: 120px;
    transition: width 0.5s;
    height: calc(100% + 1px);
    &-inactive {
        width: 0; }
    @media ( max-width: 991px ) {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 70px; } }

.float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: #25d366;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px  #999;
    z-index: 100; }

.my-float {
    margin-top: 16px; }
