
.personal-acc {
    display: flex;
    flex: 1;
    .container-fluid {}
    &__inner {
        display: flex;
        flex-direction: column;
        margin-bottom: 100px; }
    &__wrap {
        display: flex;
        justify-content: space-between;
        align-items: center; }
    &__descr {
        font-weight: bold;
        font-size: 28px;
        line-height: 33px;
        color: #39414C; }
    &__change {
        display: flex;
        align-items: center;
        margin-right: 20px;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #0093FF;
        cursor: pointer;
        &:hover {
            color: #39414C; } }
    &__wrapper {
        margin-top: 40px;
        border: 1px solid #DADADA;
        border-radius: 15px;
        padding: 30px 0 0 45px; }
    &__items {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 40px; }
    &__item {
        padding: 0; }
    &__suptitle {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #888D94;
        margin-bottom: 10px; }
    &__title {
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #39414C; }
    &__input {
        border: none;
        background: #F6F6F6;
        width: 110%;
        border-radius: 25px;
        padding: 14px 20px;
        outline: none;
        &::placeholder {
            font-weight: 500;
            font-size: 18px;
            line-height: 21px;
            color: #39414C; } }
    select {
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #39414C;
        overflow: hidden;
        appearance: none;
        background-image: url('./images/arrow.svg');
        background-repeat: no-repeat;
        background-position: right;
        background-position-x: 96%;
        option {
            font-size: 18px; } }

    &__buttons {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap-reverse; }
    &__button {
        border: none;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        padding: 14px 0;
        text-align: center;
        border-radius: 25px;
        margin-bottom: 30px;
        margin-right: 5%;
        cursor: pointer;
        outline: none !important; }
    &__btn1 {
        background: #ffffff;
        color: #39414C; }
    &__btn2 {
        width: 300px;
        color: #ffffff;
        background: #0093FF; }
    .mobile {
        display: none; }
    .desktop {
        display: block; } }


@media ( max-width: 767px ) {
    .personal-acc {
        &__inner {
            width: 100%;
            margin-top: 30px; }
        &__wrapper {
            border: none;
            padding: 0;
            margin-top: 50px; }
        &__change {
            margin-right: 0; }
        &__items {
            margin: 0; }
        &__title {
            margin-bottom: 30px; }
        &__input {
            width: 100% !important;
            margin-bottom: 24px; }
        &__button {
            width: 100%;
            margin-right: 0; }
        .desktop {
            display: none; }
        .mobile {
            display: block; } } }
@media (min-width: 768px) and (max-width: 991px) {
    .personal-acc {
        &__inner {
            width: 100%; }
        &__btn2 {
            width: 250px; } } }
@media (min-width: 992px) and (max-width: 1199px) {
    .personal-acc {
        &__btn2 {
            width: 250px; } } }
