.exam {
    display: flex;
    flex: 1;
    &__inner {
        display: flex;
        flex: 1;
        flex-direction: column; }
    &__wrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px; }
    &__title {
        font-size: 28px;
        line-height: 33px;
        color: #39414C;
        font-weight: 700;
        img {
            margin-right: 20px;
            cursor: pointer; } }
    &__timer {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 22px;
        line-height: 140%;
        color: #39414C;
        img {
            margin-right: 10px; } }
    &__items {
        position: relative;
        min-height: 100vh;
        background-image: url('./assets/img/LOGO.png');
        background-repeat: repeat-y;
        background-size: contain;
        user-select: none; }
    &__item {
        margin-bottom: 20px; }
    &__item-form {
        display: flex;
        flex-direction: column;

        table {
            max-width: 100%; }
        @media screen and (max-width: 767px) {
            table {
                max-width: 300px;
                width: auto !important; } }
        img {
            max-width: 100%;
            height: auto !important; } }
    &__item-title {
        // font-weight: bold
        font-size: 20px;
        line-height: 24px;
        color: #39414C;
        margin-bottom: 25px;
        display: flex;
        align-items: baseline;
        // flex-wrap: wrap
        ul, ol {
            padding-left: 45px; }
        p {
            margin: 0; }
        > span {
            font-weight: bold; }

        table {
            max-width: 100%; }
        @media screen and (max-width: 767px) {
            table {
                max-width: 300px;
                width: auto !important; } }
        img {
            max-width: 100%;
            height: auto !important; } }
    &__item-checkbox {
        transition: all 0.3s;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        cursor: pointer;
        &:hover {
            .exam__item-variant, .exam__item-descr {
                color:  #0093FF; } } }
    &__item-variant {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 22px;
        line-height: 29px;
        color: #39414C;
        margin-left: 15px;
        margin-right: 15px; }
    &__item-descr {
        font-weight: 500;
        font-size: 20px !important;
        line-height: 21px;
        color: #39414C; }
    &__item-descr * {
        font-family: 'Times New Roman', Times, serif !important; }
    &__item-descr p {
        margin-bottom: 0;
        p {
            margin: 0; } }
    &__btn {
        display: flex;
        justify-content: flex-end;
        cursor: pointer;
        outline: none;
        margin-bottom: 150px;
        button {
            padding: 14px 85px;
            background:  #0093FF;
            border-radius: 25px;
            border: none;
            text-align: center;
            font-weight: 500;
            font-size: 18px;
            line-height: 21px;
            text-align: center;
            cursor: pointer;
            color: #FFFFFF;
            &:focus {
                outline: none; } } } }
.correct {
    color: #4BCA1E; }
.incorrect {
    color: #e7000b; }
.notclicked {
    color: gray; }
.exam-done {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &__icon {
        img {
            width: 100%; } }
    &__result {
        margin-top: 40px;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #39414C; }
    &__subtitle {
        margin-top: 20px;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #39414C; }
    &__btn {
        margin-top: 20px;
        button {
            outline: none;
            border: none;
            background:  #0093FF;
            border-radius: 25px;
            font-weight: 500;
            font-size: 18px;
            line-height: 21px;
            text-align: center;
            padding: 15px 85px;
            color: #FFFFFF; } } }

.exam__mistake {
    color: #EF4B2E !important; }

.exam__correct {
    color: #4BCA1E !important; }


@media ( max-width: 767px ) {
    .exam {
        &__inner {
            margin-top: 30px;
            margin-left: 20px;
            margin-right: 20px;
            width: 100%; }
        &__wrapper {
            flex-wrap: wrap; }
        &__timer {
            margin-top: 40px; }
        &__item-checkbox {
            display: flex;
            align-items: center; }
        &__item-variant {}
        &__item-descr {
            text-align: left; } }

    .exam-done {
        &__btn {
            width: 100%;
            button {
                width: 100% !important;
                padding: 15px 0; } } } }
@media (min-width: 768px) and (max-width: 991px) {
    .exam {
        &__inner {
            width: 100%; } } }

.history {
    user-select: none; }
