.leaderboard-card{
  min-width: 400px;
  background-color: #007bff;
  padding: 10px 15px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  color: white;
  cursor: pointer;
  label{
    margin: 0;
    color: white;
  }
  &__image{
    width: 50px;
    height: 50px;
    border-radius: 25px;
    margin-right: 15px;
    &_placeholder{
      background-color: #888D94;
      display: inline-block;
    }
  }
  &__place{
    margin-right: 15px !important;
  }
  &__balance{
    margin-left: 15px !important;
    color: #e7bb19 !important;
  }
}
