.lessons {
    display: flex;
    overflow-y: auto;
    flex: 1;
    margin-bottom: 30px;
    &__inner {
        display: flex;
        flex-direction: column;
        flex: 1; }
    &__subtitle {
        font-weight: 700;
        font-size: 28px;
        line-height: 33px;
        color: #39414C;
        margin-bottom: 40px;
        display: flex;
        align-items: center;
        img {
            cursor: pointer;
            margin-right: 10px; } }
    &__description {

        margin-right: 24px;
        font-weight: 500;
        font-size: 18px;
        line-height: 140%;
        color: #39414C;
        margin-bottom: 40px; }
    &__item {
        margin-bottom: 24px;
        cursor: pointer;
        padding-right: 25px;
        position: relative;
        width: 25%; }

    &__img {
        width: 100%; }

    &__wrapper {
        display: flex;
        justify-content: space-between;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 15px;
        padding: 20px; }
    &__descr {
        display: flex;
        flex-direction: column;
        justify-content: flex-end; }
    &__suptitle {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #FFFFFF;
        margin-bottom: 10px; }
    &__title {
        font-weight: bold;
        font-size: 20px;
        line-height: 19px;
        color: #FFFFFF; }
    &__done {
        position: absolute;
        bottom: 20px;
        right: 48px; }

    &-collapse {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-left: 24px;
        font-weight: 500;
        font-size: 18px;
        line-height: 140%;
        border-radius: 23px;
        &__wrapper {
             background: #F6F6F6;
             display: flex;
             flex-direction: column;
             width: 100%; }
        &.close {
            height: 0; }
        &__title {
            &_div {
                color: white;
                padding: 10px 20px;
                display: flex;
                border-radius: 22.5px;
                background:  #0093FF;
                justify-content: space-between;
                cursor: pointer;
                p {
                    margin: 0; } } }
        &__items {
            list-style: none;
            height: 100%;
            max-height: 100%;
            overflow-y: auto;
            &.close {
                height: 0;
                min-height: 0; } }
        &__item {
            padding: 10px 20px;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            &:hover {
                color:  #0093FF; }
            &_lock {
                position: absolute;
                top: 100%;
                width: 120%;
                right: 0;
                text-align: right;
                z-index: 10; } } } }

.lesson {
    &_tab {

        display: flex;
        margin-bottom: 30px;
        &__item {
            display: flex;
            flex-direction: column;
            font-size: 18px;
            font-weight: 500;
            cursor: pointer;
            &.active {
                color:  #0093FF; }
            .active_underline {
                height: 4px;
                background:  #0093FF;
                border-radius: 2px;
                display: none;
                margin-top: 10px;
                &.active {
                    display: block; } }
            &:nth-child(2) {
                margin-left: 24px; } } }
    &_content {
        display: flex;

        flex: 1;
        &__video {
            width: 65%;

            // .plyr
            //     &--paused

            //     &__poster
            //         background-size: cover
 } } }            //         z-index: 2
.exam {
    flex: 1;
    &__items {
        flex: 1; }

    &__item-title * {
        font-family: "Times New Roman", "serif" !important;
        font-size: '14pt' !important;
        // font-weight: 500 !important
        line-height: '21px' !important; } }

@media ( max-width: 991px ) {
    .lessons {
        &__inner {
            width: 100%;
            margin-top: 30px; }
        &__subtitle {
            margin-bottom: 30px;
            font-size: 24px; }

        &__description {

            margin-right: 20px; }
        &__item {
            width: 100%;
            margin-right: 0;
            padding: 0; }
        &-collapse {
            margin: 0;
            margin-top: 30px;
            &__title {
                &_div {} } } }

    .lesson {
        &_content {
            flex-wrap: wrap;
            &__video {
                width: 100%; } } } }
