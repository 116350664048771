.navbar {
    min-height: 100vh;
    width: 20%;
    background: url('./images/navbar-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom center;
    padding-left: 20px;
    overflow-x: hidden;
    transition: width 0.5s;
    &-active {
        width: 100vw!important; }
    &__logo {
        padding-top: 30px;
        display: inherit;
        img {
            width: 150px; } }
    &__menu {
        margin-top: 90px; }
    &__link {
        a {
            color: #ffffff; }
        cursor: pointer;
        padding: 13px 20px;
        list-style-type: none;
        margin-bottom: 10px;
        width: 220px;
        border-radius: 15px;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #ffffff;
        display: flex;
        align-items: center;
        transition: all 0.2s;
        svg, img {
            margin-right: 15px; }
        .icon_active {
            display: none; }
        &-active {
            background: white;
            color: #276FEB;
            text-decoration: none;
            .icon_active {
                display: block; }
            .icon_inactive {
                display: none; }
            a, svg {
                fill: #276FEB;
                color: #276FEB; } }
        &:hover {
            background: white;
            color: #276FEB;
            text-decoration: none;
            .icon_active {
                display: block; }
            .icon_inactive {
                display: none; }
            a, svg {
                fill: #276FEB;
                color: #276FEB; } } } }



.topbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 25px;
    position: relative;
    background-color: #fafafa;
    transform: translate3d(0,0,0);
    &__user {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        user-select: none;
        position: relative;
        @media ( max-width: 767px ) {
            &_icon {
                margin-right: 10px; }
            &_email {
                display: none; } }

        p {
            margin: 0 10px 0 10px; } }
    &__menu {
        display: none;
        align-items: center;
        img {
            width: 24px; } }
    &__logo {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        display: none;
        align-items: center;
        justify-content: center;
        img {
            width: 150px; } }
    &-desktop__logo {
        cursor: pointer; }
    &__toggle-card {
        top: 120%;
        z-index: 99999;
        right: 24px;
        padding: 20px 30px;
        background: #FFFFFF;
        box-shadow: 1px 0px 9px 1px rgba(0, 0, 0, 0.05);
        border-radius: 15px;
        position: absolute;
        &__item {
            display: flex;
            align-items: center;
            font-size: 18px;
            line-height: 140%;
            cursor: pointer;
            img {
                margin-right: 15px; }
            &:nth-child(1) {
                margin-bottom: 20px; } } } }
@media ( max-width: 991px ) {
    .topbar {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 15px;
        justify-content: space-between;
        background: white;
        transition: background-color step-end 500ms;
        margin-bottom: 40px;
        position: fixed;
        z-index: 100;
        &-active {
            background-color: transparent;
            transition: background-color step-start 50ms; }
        &__user {
            &_icon {
                margin-right: 10px; }
            &_email {
                display: none; } }
        &__logo {
            display: flex; }
        &__menu {
            display: flex; }

        &-desktop__logo {
            display: none; } }
    .navbar {
        min-width: 0;
        width: 0;
        padding: 0;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 50;
        background-position: center bottom;
        &__logo {
            display: none; }
        &__menu {
            margin-top: 160px;
            padding-left: 20px; } } }
