.subscript {
    display: flex;
    position: relative;
    flex: 1;
    &__inner {
        display: flex;
        flex: 1;
        flex-direction: column; }
    &__suptitle {
        font-weight: 700;
        font-size: 28px;
        line-height: 33px;
        color: #39414C;
        margin-bottom: 40px;
        img {
            cursor: pointer;
            margin-right: 12px; } }
    &__directions {
        margin-left: 24px;
        margin-bottom: 30px; }

    &__direction-click {
        background: #0093FF !important;
        color: #FFFFFF !important; }
    &__direction {
        outline: none !important;
        border: none;
        padding: 8px 20px;
        background: #EAEDF5;
        border-radius: 20px;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #39414C;
        text-align: center;
        cursor: pointer;
        & + .subscript__direction {
            margin-left: 24px; } }

    &__items {
        display: flex;
        flex-wrap: wrap; }
    &__item {
        padding: 20px;
        border: 1px solid #DADADA;
        overflow: hidden;
        color: #39414C;
        margin-bottom: 24px;
        width: 330px;
        border-radius: 15px;
        margin-left: 24px; }
    &__up {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap; }
    &__period1 {
        background: linear-gradient(139.73deg, #0093FF 3.85%, #0058BA 99.1%); }
    &__period2 {
        background: linear-gradient(139.13deg, #EF4B2E 2.05%, #850000 100%); }
    &__period3 {
        background: linear-gradient(139.73deg, #FFE507 3.85%, #C7B300 99.1%);
        color: #39414C !important; }
    &__period {
        border-radius: 20px;
        padding: 8px 20px;
        text-align: center;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #FFFFFF; }
    &__test__period {
        background: #EAEDF5;
        padding: 8px 20px;
        font-size: 18px;
        line-height: 21px;
        border-radius: 22.5px;
        color: black;
        &:focus {
            outline: none; } }
    &__cost {
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        color: #39414C;
        display: flex;
        align-items: center;
        img {
            margin-left: 5px; } }
    &__descr {
        margin-top: 30px;
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        color: #39414C; }
    &__box {
        margin-top: 15px; }
    &__lesson {
        margin-bottom: 5px;
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        color: #39414C; }
    &__btn {
        margin-top: 30px;
        border: none;
        background: #0093FF;
        border-radius: 25px;
        width: 100%;
        padding: 16px 0;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        color: #FFFFFF;
        cursor: pointer; } }

@media ( max-width: 767px ) {
    .subscript {
        &__inner {
            margin-top: 30px; }
        &__suptitle {
            margin-bottom: 30px; }
        &__directions {
            overflow: scroll !important; }
        &__direction {
            overflow: scroll !important;
            margin-right: 20px;
            margin-bottom: 20px;
            & + .subscript__direction {
                margin-left: 0; } }
        &__items {
            justify-content: center; }
        &__item {
            margin-bottom: 30px;
            margin-left: 20px;
            margin-right: 20px;
            width: 100%; } } }

.card {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    padding: 10px 15px;
    &-title {
        font-size: 18px;
        display: flex;
        justify-content: space-between;
        align-items: baseline; }
    &-price-div {
        margin-left: auto; }
    &-price {
        font-size: 22px;
        margin-right: 10px; }
    &-old-price {
        font-size: 22px;
        color: #6c757d; } }
