.home {
    display: flex;
    &__inner {
        flex: 1; }
    &__suptitle {
        font-weight: 700;
        font-size: 28px;
        line-height: 33px;
        color: #39414C;
        margin-bottom: 40px; }
    &__items {
        display: flex;
        flex-wrap: wrap; }

    &__item {
        margin-bottom: 24px;
        cursor: pointer;
        padding-right: 25px;
        position: relative;
        width: 25%; }
    &__img {
        width: 100%; }

    &__wrapper {
        display: flex;
        justify-content: space-between;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 15px;
        padding: 20px; }
    &__descr {
        color: #ffffff; }
    &__title {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 10px; }
    &__subtitle {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px; } }


@media ( max-width: 767px ) {
    .course {
        &__inner {
            margin-top: 30px; }
        &__items {
            justify-content: center; }
        &__item {
            padding-right: 0;
            width: 100%; } } }
